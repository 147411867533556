<template>
    <b-overlay :show="showLoader">
        <b-card >
            <b-alert class="p-1 text-center" show variant="warning">Po spremembi soseske, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe niso vidne, poskusite osvežiti spletno stran.</b-alert>
            <validation-observer ref="validate">
                <neighborhood-component ref="neighborhoodEdit" :model="object" :type="'edit'"/>
            </validation-observer>
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="validationForm">Shrani spremembe</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import NeighborhoodComponent from './NeighborhoodComponent.vue'
    import {BButton, BCard, BAlert, BOverlay} from 'bootstrap-vue'
    import { ValidationObserver } from 'vee-validate'
    export default {
        components: { 
            NeighborhoodComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay,
            ValidationObserver
        },            
        data() {
            return {
                showLoader: false,
                neighborhood_id: null,
                object: null
            }
        },
        methods:{
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        if (this.object.description_list.length > 1 && this.object.description_list.length < 11) this.sendEditedData()
                        else this.$printWarning('Število dodanih točk mora biti med 2 in 10')
                    }
                })
            },
            async sendEditedData() {
                this.showLoader = true

                try {
                    const imageUploadResult = await this.$refs.neighborhoodEdit.$refs.imageSelector.UploadImagesToAPI()

                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        return
                    }

                    if (!imageUploadResult) {
                        this.$printError('Prišlo je do napake pri nalaganju slik')
                        return
                    }

                    await this.$http.patch(`/api/management/v1/neighborhoods/${this.neighborhood_id}`, this.object)

                    this.$printSuccess('Soseska je posodobljena')
                    setTimeout(() => {
                        this.$router.push({name: 'neighborhoods-list'})
                    }, 1000)
                } catch (error) {
                    this.$printError(error.message)
                } finally {
                    this.showLoader = false
                }

            },
            async loadData() {
                this.showLoader = true
                try {
                    const test = await this.$http.get(`/api/management/v1/neighborhoods/${ this.neighborhood_id}`)
                    this.object = test.data
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        },
        created() {
            this.neighborhood_id = this.$route.params.neighborhood_id
        }
    }
</script>

<style scoped>
    .item-description-list{
        border: 2px solid grey;
        padding: 35px 10px 10px 10px;
        position: relative;
    }

    .delete-from-list{
        color: red;
        font-weight: 900;
        font-size: 17px;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
</style>